<template>
  <backoffice-container>
    <!-- General -->
    <div v-if="isLoading" class="mb-5">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else>
      <b-card>
        <!-- Unshare -->
        <div class="trash-icon">
          <feather-icon
            v-b-tooltip.hover.bottom
            icon="Trash2Icon"
            :title="$t('unshare.tooltip')"
            size="20"
            class="text-primary pointer share-icon"
            @click="isUnshareModalVisible = true"
          />
        </div>
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <h4>
            {{ $t("backoffice.roles.role-detail") }}
          </h4>
          <b-button variant="primary" @click="onSubmit()">
            {{ $t("form.actions.save") }}
          </b-button>
        </div>
        
        <!-- Body -->
        <validation-observer>
          <!-- Form -->
          <b-form>
            <b-row>
              <b-col cols="12" lg="6">
                <!-- Name -->
                <b-form-group class="flex-grow-1 mb-1">
                  <label for="rol-name" class="font-weight-bold">
                    {{ $t("backoffice.roles.name") }}*</label
                  >
                  <form-translation-table
                    v-model="currentClassifier.name"
                    class="mt-50"
                    :value-placeholder="$t('layouts.widget-name')"
                    label-attribute="name"
                    value-attribute="text"
                    @input="handleTitleChange"
                  />
                  <small v-if="isValid === false" class="text-danger mt-25">
                    {{ $t("backoffice.classifiers.validator-message") }}
                  </small>
                </b-form-group>

                <!-- Description -->
                <b-form-group label-for="description">
                  <label class="font-weight-bold">
                    {{ $t("backoffice.roles.description") }}
                  </label>
                  <form-translation-table
                    v-model="currentClassifier.description"
                    class="mt-50"
                    :value-placeholder="$t('layouts.widget-name')"
                    :is-description="true"
                    label-attribute="description"
                    value-attribute="text"
                  />
                </b-form-group>

                <!-- is Active? -->
                <b-form-group>
                  <label class="font-weight-bold">
                    {{ $t("common.active") }} - {{ $t("backoffice.classifiers.activate-msg") }}
                  </label>
                  <b-form-checkbox 
                    v-model="currentClassifier.isActive" 
                    switch 
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <!-- Multiple -->
                <b-form-group>
                  <label class="font-weight-bold">
                    {{ $t("backoffice.classifiers.multiple-info") }}
                  </label>
                  <!-- Multiple checkbox -->
                  <b-form-checkbox
                    v-model="currentClassifier.multiple"
                    switch
                  />
                </b-form-group>
                
                <!-- Models -->
                <b-form-group>
                  <label for="rol-name" class="font-weight-bold">
                    Apps  
                  </label>
                  <v-select
                    v-model="currentClassifier.modelType"
                    class="select-control p-0"
                    :placeholder="$t('form.select-multiple-placeholder')"
                    clearable
                    :multiple="true"
                    :options="modelOptions"
                  />
                </b-form-group>

                <!-- Share subcommunities -->
                <b-form-group>
                  <label class="font-weight-bold">
                    {{ $t("backoffice.classifiers.share-subcommunities") }}
                  </label>
                  <b-form-checkbox 
                    v-model="currentClassifier.share" 
                    switch 
                  />
                </b-form-group>

                <!-- Visibility -->
                <b-form-group>
                  <label class="font-weight-bold">
                    {{ $t("common.private") }}
                  </label>
                  <b-form-checkbox 
                    v-model="currentClassifier.isPrivate" 
                    switch 
                  />
                </b-form-group>

                <!-- Colors -->
                <b-row>
                  <!-- Background color -->
                  <b-col>
                    <b-form-group>
                      <label class="font-weight-bold">
                        {{ $t("backoffice.classifiers.background-color") }}
                      </label>
                      <b-form-input
                        v-model="currentClassifier.backgroundColor"
                        type="color"
                        class="banner-input"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Font color -->
                  <b-col>
                    <b-form-group>
                      <label class="font-weight-bold">
                        {{ $t("backoffice.classifiers.color") }}
                      </label>
                      <b-form-input
                        v-model="currentClassifier.fontColor"
                        type="color"
                        class="banner-input"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <!-- Preview -->
                <div class="preview my-1">
                  <p class="preview--title">
                    {{ $t("backoffice.classifiers.preview") }}
                  </p>
                  <div
                    class="preview--classifier d-block mx-auto my-1"
                    :style="`background-color: ${currentClassifier.backgroundColor}; border-radius: 1.2rem;`"
                  >
                    <span
                      :style="`color: ${currentClassifier.fontColor}`"
                      class="font-weight-bolder"
                      >
                      {{ $t("backoffice.classifiers.new-classifier") }}
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>

      <!-- Classifiers TABLE -->
      <b-card>
        <div class="d-flex justify-content-between flex-wrap my-1">
          <h4>
            {{ $t("backoffice.classifiers.name") }}
          </h4>
          <div class="d-flex">
            <b-button v-b-modal.new-subClassifier variant="primary">
              <feather-icon icon="PlusCircleIcon" class="text-white" />
            </b-button>
          </div>
        </div>
        <div>
          <b-table
            v-if="classifiers.length > 0"
            class="position-relative"
            :items="classifiers"
            responsive
            :small="isSmall"
            :fields="fields"
            primary-key="id"
            show-empty
            empty-text="loading"
          >
            <!-- Column: Name -->
            <template #cell(name)="{ item }">
              <div class="d-flex align-items-center">
                <div class="ml-1">
                  <p class="font-weight-bold mb-0 text-dark">
                    {{ translateTranslationTable(
                        $store.state.locale.currentLocale,
                        item.name
                      ) }}
                  </p>
                </div>
              </div>
            </template>

            <!-- Column: Style -->
            <template #cell(preview)="{ item }">
              <div
                class="preview--classifier ml-1"
                :style="`background-color: ${currentClassifier.backgroundColor}; border-radius: 1.2rem; padding: 0.10rem 0.40rem; min-width: 90px`"
              >
                <small
                  :style="`color: ${currentClassifier.fontColor}`"
                  class="font-weight-bolder"
                  >
                  {{ translateTranslationTable(
                      $store.state.locale.currentLocale,
                      item.name
                    ) }}
                </small>
              </div>
            </template>

            <!-- Column: Times used -->
            <template #cell(timesUsed)="{ item }">
              <div class="text-nowrap">
                <p class="mb-0 ml-1 pl-25">
                  {{ translateTranslationTable(
                      $store.state.locale.currentLocale,
                      item.timesUsed
                    ) }}
                </p>
              </div>
            </template>
            <!-- Column: Creation date -->
            <template #cell(creation)="{ item }">
              <div class="text-nowrap">
                <p class="mb-0 ml-1 pl-25">
                  {{ new Date(item.createdAt).toLocaleString("en-GB") }}
                </p>
              </div>
            </template>
            <!-- Column: Edit icon -->
            <template #cell(edit)="{ item }">
              <div class="d-flex flex-wrap">
                <feather-icon
                  v-b-modal.new-subClassifier
                  icon="Edit2Icon"
                  size="20"
                  class="text-primary d-block ml-2"
                  style="cursor: pointer"
                  @click="handleEditClassifier(item)"
                />
                <!-- Unshare -->
                <feather-icon
                  v-b-tooltip.hover.bottom
                  icon="Trash2Icon"
                  :title="$t('unshare.tooltip')"
                  size="20"
                  class="text-primary pointer ml-1"
                  @click="handleClassifier(item)"
                />
              </div>
            </template>
          </b-table>
          <div v-else class="horizontal-placeholder d-block">
            <div>
              <img :src="classifiersPlaceholder" class="d-block" />
            </div>
            <div>
              <p class="text-primary text-center">
                {{ $t("available.message", {
                    itemName: $t("backoffice.classifiers.name"),
                  }) }}
              </p>
            </div>
          </div>
        </div>
        <b-modal
          id="new-subClassifier"
          :title="
            isEditingClassifier
              ? $t('backoffice.classifiers.edit-classifier')
              : $t('backoffice.classifiers.new-classifier')
          "
          :ok-title="$t('form.actions.save')"
          ok-only
          :ok-disabled="disabled"
          @hidden="resetForm()"
          @ok="isEditingClassifier ? editClassifiers() : saveClassifiers()"
        >
          <b-tabs v-if="!isEditingClassifier">
            <b-tab
              active
              :title="$t('backoffice.classifiers.add.individual')"
              content-class="mt-2"
              class="mt-2"
              title-link-class="px-0 font-weight-bolder"
            >
              <b-form-group class="flex-grow-1 mb-1">
                <label for="rol-name" class="font-weight-bold">
                  {{ $t("backoffice.roles.name") }}*</label
                >
                <b-form-input
                  v-if="typeof currentSubClassifier.name === 'string'"
                  v-model="currentSubClassifier.name"
                  @input="validator()"
                />
                <form-translation-table
                  v-else
                  v-model="currentSubClassifier.name"
                  class="mt-50"
                  :value-placeholder="$t('layouts.widget-name')"
                  label-attribute="name"
                  value-attribute="text"
                  @input="validator()"
                />
                <small v-if="isValidName === false" class="text-danger mt-25">
                  {{ $t("backoffice.classifiers.validator-message") }}
                </small>
              </b-form-group>
            </b-tab>
            <b-tab
              :title="$t('backoffice.classifiers.add.multiple')"
              content-class="mt-2"
              class="mt-2"
              title-link-class="px-0 ml-2 font-weight-bolder"
            >
              <div v-if="!isEditingClassifier">
                <span
                  class="d-flex align-items-center justify-content-between mb-1"
                >
                  <b-button
                    variant="link"
                    class="p-0 pointer font-weight-bold"
                    @click="uploadFileVisible = !uploadFileVisible"
                  >
                    {{ $t("members.edit.classifiers.import") }}
                  </b-button>
                  <b-badge
                    variant="light-primary"
                    class="ml-25 font-weight-normal pointer"
                    @click="downloadCsv()"
                  >
                    <feather-icon icon="DownloadIcon" class="mr-25" />
                    {{ $t('download.csv') }}
                  </b-badge>
                </span>
              </div>
              <div v-if="uploadFileVisible && !isEditingClassifier">
                <file-upload
                  v-model="csvFile"
                  type="csv"
                  class="w-100 my-1"
                  cancelable
                  :placeholder="$t('media.form.file.placeholder')"
                  :drop-placeholder="$t('media.form.file.drop-placeholder')"
                />
              </div>
            </b-tab>
          </b-tabs>
          <b-form-group v-else class="flex-grow-1 mb-1">
            <label for="rol-name" class="font-weight-bold">
              {{ $t("backoffice.roles.name") }}*
            </label>
            <b-form-input
              v-if="typeof currentSubClassifier.name === 'string'"
              v-model="currentSubClassifier.name"
            />
            <form-translation-table
              v-else
              v-model="currentSubClassifier.name"
              class="mt-50"
              :value-placeholder="$t('layouts.widget-name')"
              label-attribute="name"
              value-attribute="text"
            />
            <small v-if="isValidName === false" class="text-danger mt-25">
              {{ $t("backoffice.classifiers.validator-message") }}
            </small>
          </b-form-group>
        </b-modal>
      </b-card>
    </div>

    <!-- Unshare or delete modal -->
    <unshare-modal
      v-model="isUnshareModalVisible"
      :model-type="classifier !== null ? 'classifier' : 'type-classifier'"
      @remove-item="handleRemoveItem"
    />
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import vSelect from "vue-select";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ClassifiersPlaceholder from "@/assets/images/placeholders/light/classifiers.svg";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
//import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  name: "ClassifierDetail",
  components: {
    ValidationObserver,
    BackofficeContainer,
    vSelect,
    FormTranslationTable,
    UnshareModal,
    FileUpload,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentClassifier: {
        name: "",
        description: "",
        modelType: "",
        backgroundColor: "#5dbfc9",
        fontColor: "#ffffff",
        multiple: false,
        share: false,
        isPrivate: false,
        isActive: false,
      },
      currentSubClassifier: {
        name: {},
        classifierKey: "",
      },
      subClassifiers: [],
      isValid: null,
      isEditingClassifier: false,
      required,
      isLoading: false,
      existSomeName: false,
      isValidName: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      isUnshareModalVisible: false,
      classifier: null,
      uploadFileVisible: false,
      csvFile: null,
      classifiersTemplate: ["classifier 1", "classifier 2"],
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    disabled() {
      return Object.values(this.currentSubClassifier.name).length === 0 && this.csvFile == null;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    type() {
      return this.types?.filter(({ key }) => key === this.$route.params.id)[0];
    },
    total() {
      return this.$store.getters.type?.meta?.total;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'payment',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app.addon[this.locale]);
        }
      });
      return newObj;
      // return modelTypesObj.map(
      //   (item) => item.addon[this.locale]
      // );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      return this.$store.getters.classifiersType.unpaginated;
    },
    classifiers() {
      return this.$store.getters.classifiersBO;
    },
    classifiersPlaceholder() {
      return ClassifiersPlaceholder;
    },
    typeModel() {
      if (Array.isArray(this.type?.modelType)) {
        if (typeof this.type?.modelType[0] === "object") {
          return this.type?.modelType[0][1];
        } else {
          return this.type?.modelType.map((i) => i.toLowerCase());
        }
      }
      return [];
    },
    model() {
      let model = [];
      if (Array.isArray(this.type?.modelType)) {
        model = this.$store.getters.modelTypes.filter(
          (item) =>
            this.typeModel?.includes(item?.addonSingular) ||
            this.typeModel?.includes(item.key)
        );
        return model.map((item) => item?.addon[this.locale]);
      }
      return this.$store.getters.modelTypes.filter(
        (item) => item?.addonSingular === this.typeModel
      )[0]?.addon[this.locale];
    },
    allModelOptions() {
      return this.$store.getters.modelTypes;
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$t("backoffice.roles.name"),
        },
        {
          key: "preview",
          label: this.$t("backoffice.classifiers.preview"),
        },
        {
          key: "timesUsed",
          label: this.$t("backoffice.classifiers.times-used"),
        },
        {
          key: "creation",
          label: this.$tc("backoffice.classifiers.creation-date"),
        },
        {
          key: "edit",
          label: this.$tc("backoffice.classifiers.actions"),
        },
      ];
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchClassifierType();
    const modelTypes = await this.getModelTypes();
    // console.log('modelTypes', modelTypes);
    // console.log('this.type', this.type);

    if(this.type){
      this.currentClassifier = {
        name: this.type.name,
        description: this.type.description ? this.type.description : {},
        modelType: this.model,
        backgroundColor: this.type.backgroundColor,
        fontColor: this.type.fontColor,
        multiple: this.type.multiple,
        share: this.type.isSharedGlobally,
        isPrivate: this.type.isPrivate,
        isActive: this.type.isActive,
      };
    }
    await this.fetchClassifiersByType();
    this.isLoading = false;
    this.updateBreadcrumbs();
    this.subClassifiers = this.classifiers.map(item => item);
  },
  methods: {
    async fetchClassifierType() {
      const result = await this.$store.dispatch("getItems", {
        itemType: "backoffice/classifierTypes",
        customName: "classifiersType",
        forceAPICall: true,
        page: 1,
        perPage: 1000,
        requestConfig: {
          isForBackoffice: true,
        }
      });
      // console.log('result', result);
      return result;
    },
    validator() {
      this.isValidName = this.classifiers.every(
        (classifier) =>
          classifier.name[this.locale] !==
          (this.currentSubClassifier.name[this.locale] ||
            this.currentSubClassifier.name)
      );
    },
    translateTranslationTable,
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: "backoffice.classifiers.name",
          to: { name: "classifiers" },
        },
        {
          text: this.currentClassifier.name
            ? this.translateTranslationTable(
                this.locale,
                this.currentClassifier.name
              )
            : "backoffice.classifiers.classifier-detail",
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    async onSubmit() {
      let model = [];
      if (typeof this.currentClassifier.modelType === "string") {
        model = [
          this.$store.getters.modelTypes.filter(
            (item) =>
              item?.addon[this.locale] === this.currentClassifier.modelType
          )[0]?.addon.en,
        ];
      }
      if (
        typeof this.currentClassifier.modelType !== "string" &&
        this.currentClassifier.modelType !== null
      ) {
        this.$store.getters.modelTypes.map((item) => {
          if (
            this.currentClassifier?.modelType?.includes(
              item?.addon[this.locale]
            )
          ) {
            model.push(item.addon.en);
          }
        });
      }
      try {
        // if (this.currentClassifier.name === '' || this.currentClassifier.name === {} || this.isValid === false) {
        //   this.notifyError(this.$t('backoffice.classifiers.messages.edit-error'));
        // }
        await this.$store.dispatch("editClassifiersType", {
          classifier: {
            classifierTypeKey: this.$route.params.id,
            name: this.currentClassifier.name,
            description: this.currentClassifier.description,
            modelType: Array.isArray(this.currentClassifier.modelType)
              ? this.allModelOptions
                  .filter((item) =>
                    this.currentClassifier.modelType?.includes(
                      item.addon[this.locale]
                    )
                  )
                  .map((item) => item.addonSingular)
              : this.allModelOptions
                  .filter((item) =>
                    [this.currentClassifier.modelType]?.includes(
                      item.addon[this.locale]
                    )
                  )
                  .map((item) => item.addonSingular),
            backgroundColor: this.currentClassifier.backgroundColor,
            fontColor: this.currentClassifier.fontColor,
            multiple: this.currentClassifier.multiple ? 1 : 0,
            sharedGlobally: this.currentClassifier.share ? 1 : 0,
            isPrivate: this.currentClassifier.isPrivate ? 1 : 0,
            //activar el classifier type para poder usarlo en esta comunidad
            isActive: this.currentClassifier.isActive ? 1 : 0, 
          },
        });
        this.notifySuccess(
          this.$t("backoffice.classifiers.messages.edit-success")
        );
        this.fetchClassifierType();
      } catch {
        this.notifyError(this.$t("backoffice.classifiers.messages.edit-error"));
      }
    },
    async saveClassifiers() {
      if (
        this.isValidName &&
        typeof this.currentSubClassifier.name !== "undefined" &&
        Object.values(this.currentSubClassifier.name).length > 0
      ) {
        try {
          await this.$store.dispatch("addClassifiersBO", {
            name: this.currentSubClassifier.name,
            classifierTypeKey: this.$route.params.id,
          });
          await this.fetchClassifiersByType();
          this.notifySuccess(
            this.$t("backoffice.classifiers.messages.permissions-success")
          );
          this.currentSubClassifier = {
            name: {},
            classifierKey: "",
          };
          this.isValid = null;
        } catch {
          this.notifyError(
            this.$t("backoffice.classifiers.messages.permissions-error")
          );
        }
      }
      if (this.csvFile !== null) {
        try {
          await this.$store.dispatch("importFiles", {
            importType: "backoffice/classifiers/import",
            file: this.csvFile,
            customName: "classifiers",
            classifierTypeKey: this.$route.params.id,
          });
          await this.fetchClassifiersByType();
          this.notifySuccess(
            this.$t("backoffice.members.messages.import-success")
          );
          this.csvFile = null;
          this.uploadFileVisible = false;
        } catch {
          this.notifyError(this.$t("backoffice.members.messages.import-error"));
        }
      }
    },
    async editClassifiers() {
      if (Object.values(this.currentSubClassifier.name).length > 0) {
        try {
          await this.$store.dispatch("editClassifiersBO", {
            name: this.currentSubClassifier.name,
            classifierKey: this.classifierKey,
          });
          await this.fetchClassifiersByType();
          this.notifySuccess(
            this.$t("backoffice.classifiers.messages.edit-success")
          );
          this.currentSubClassifier = {
            name: {},
            classifierKey: "",
          };
          this.isValid = null;
          await this.fetchClassifiersByType();
        } catch {
          this.notifyError(
            this.$t("backoffice.classifiers.messages.edit-error")
          );
        }
      } else {
        this.notifyError(
          this.$t("backoffice.classifiers.messages.permissions-error")
        );
      }
    },
    handleTitleChange() {
      const allTypesExceptCurrent = this.types?.filter(
        (item) => item.key !== this.$route.params.id
      );
      const completeTitles = Object.values(this.currentClassifier.name).filter(
        (item) => item !== ""
      );
      this.isValid =
        allTypesExceptCurrent.every(
          (type) =>
            type.name[this.locale] !== this.currentClassifier.name[this.locale]
        ) && completeTitles.length > 0;
      this.updateBreadcrumbs();
    },
    async getModelTypes() {
      await this.$store.dispatch("getModelTypes");
    },
    resetForm() {
      this.currentSubClassifier = {
        name: {},
        classifierKey: "",
      };
      this.isValid = null;
      this.isEditingClassifier = false;
    },
    async fetchClassifiersByType() {
      await this.$store.dispatch("getClassifiersBO", {
        key: this.$route.params.id,
        requestConfig: {
          isForBackoffice: true,
        }
      });
    },
    handleEditClassifier(item) {
      this.isEditingClassifier = true;
      this.currentSubClassifier.name = typeof item.name === 'string' ? item.name : { ...item.name };
      this.classifierKey = item.key;
    },
    async handleRemoveItem(executeDelete) {
      // Check if it is a classifier or a classifier type...
      if (this.classifier !== null) {
        // Delete classifier
        this.handleRemoveClassifier(executeDelete);
      } else {
        // Delete classifier type
        if (executeDelete === true) {
          try {
            if(this.type.isCreatedLocally){
              // If the type is created locally, we can delete it
              await this.$store.dispatch("deleteClassifierTypeBO", {
                type: "backoffice/classifierTypes/delete",
                customName: "classifiersType",
                requestConfig: {
                  classifierTypeKey: this.type.key,
                },
              });

              // nos vamos al index de classifiers types
              // console.log('go to classifiers index');
              this.$router.push({
                name: "classifiers",
              });
            } else {
              // but if it is shared, and was created somewhere else, we can't delete it, we will unshare it
              const classifierType = {
                modelName: "classifier_type",
                tableName: "classifier_types",
                modelKey: this.type.key,
              };
              await this.$store.dispatch("unshareModel", {
                item: classifierType,
                customName: "classifiersType",
              });

              // nos quedamos en la vista de classifiers types
              // console.log('we stay in classifiers index');
              this.$router.push({
                name: "classifiers",
                params: { communityId: this.$route.params.communityId },
              });
            }
            
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: this.$t("unshare.toast-messages.success", {
                  item: this.$t("unshare.singular-items.type-classifier"),
                }),
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          } catch {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: this.$t("unshare.toast-messages.error", {
                  item: this.$t("unshare.singular-items.type-classifier"),
                }),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }
      }
    },
    handleClassifier(item) {
      this.classifier = item;
      this.isUnshareModalVisible = true;
    },
    async handleRemoveClassifier(executeDelete) {
      if (executeDelete === true) {
        try {
          await this.$store.dispatch("unshareClassifierBO", {
            type: "backoffice/classifiers/delete",
            customName: "classifiersBO",
            requestConfig: {
              classifierKey: this.classifier.key,
              key: this.type.key,
            },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.classifier"),
              }),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.classifier"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        this.classifier = null;
      }
    },
    downloadCsv() {
      let csv = "name;\r\n";
      this.classifiersTemplate.forEach((row) => {
        csv += row;
        csv += "\r\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
        csv.replace(/,/g, ";")
      )}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = "CSV_template_classifiers.csv";
      hiddenElement.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 1.2rem;
  padding: 1rem;
  position: relative;
  &--classifier {
    padding: 0.3rem 0.5rem;
    width: fit-content;
    text-align: center;
    border-radius: 0.358rem;
  }
  &--title {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 0.5rem;
    background-color: #ffffff;
  }
}
.share-icon {
  position: absolute;
  margin-top: -60px;
  right: 0;
  cursor: pointer;
}
</style>
